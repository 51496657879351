import React from 'react'
import { Stack } from '@mui/material'
import Events from '../Components/Events'
import Header from '../Components/Header'

const EventsPage = () => {
    return (
        <Stack spacing={0} sx={{ height: '100vh' }} backgroundColor='#e3e3e3'>
            <Header text="The U-Turn Events" />
            <Events />
        </Stack>
    )
}

export default EventsPage