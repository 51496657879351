import { createBreakpoints } from "@mui/system";
import { createTheme } from "@mui/material/styles";

const breakpoints = createBreakpoints({});
const theme = createTheme({
    typography: {
        h6: {
            fontSize: 20,
            [breakpoints.up("lg")]: {
                fontSize: 20
            },
            [breakpoints.down("md")]: {
                fontSize: 15
            },
            [breakpoints.up("sm")]: {
                fontSize: 10
            },
            [breakpoints.up("xs")]: {
                fontSize: 14
            }
        }
    }
});

export default theme;