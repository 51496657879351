import React from 'react'
import { Stack } from '@mui/material'
import Partners from '../Components/Partners'
import Header from '../Components/Header'

const PartnersPage = () => {
    return (
        <Stack spacing={0} sx={{ height: '100vh' }} backgroundColor='#e3e3e3'>
            <Header text="Join Us" />
            <Partners />
        </Stack>
    )
}

export default PartnersPage