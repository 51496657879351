import React from 'react'
import { Container, Grid } from '@mui/material'
import { team } from '../data'
import TeamMember from './TeamMember'

const About = () => {

    return (
        <Container sx={{ maxWidth: '100%', marginTop: 4, marginBottom: 4 }}>
            <Grid container spacing={4} sx={{ display: 'flex', flexWrap: 'wrap', margin: 6, gap: 4 }}>
                {team.map(member => (
                    <TeamMember member={member} key={member.id}/>
                ))}
            </Grid>
        </Container>
    )
}

export default About