import React from 'react'
import { Stack } from '@mui/material'
import Main from '../Components/Main'
import Header from '../Components/Header'
import { aboutUs } from '../data'

const Home = () => {
    return (
        <Stack spacing={0} backgroundColor='#e3e3e3'>
            <Header text={aboutUs.desc} flag={true}/>
            <Main />
        </Stack>
    )
}

export default Home