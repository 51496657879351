import React from 'react'
import { useLocation } from "react-router-dom"
import { Box, Stack, Avatar, Button, Card, CardActions, CardContent, CardMedia, Typography  } from '@mui/material'
import { team } from '../data'
import { useNavigate } from 'react-router-dom'
import Header from '../Components/Header'

const TeamMemberPage = () => {
    const location = useLocation()
    const teamId = location.pathname.split("/")[2]
    const member = team[teamId]
    const navigate = useNavigate()
    return (
        <Stack spacing={0}>
            <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 2}}>
                <Card sx={{maxWidth: 500, display: 'inline-block'}} >
                    <CardMedia sx={{height: 250}}>
                        <Avatar alt={member.name} src={member.img} sx={{justifyContent: 'center', alignItems: 'center', height: 250, width: 250, ml: 15, mr: 15, mt: 2}}/>
                    </CardMedia> 
                    <CardContent>
                        <Typography variant='h4'>{member.name}</Typography>
                        <Typography variant='h6'>{member.title}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={() => navigate('/about')}>Show Less</Button>
                    </CardActions>
                    <CardContent>
                        <Typography paragraph sx={{flexWrap: 'wrap'}}>{member.desc}</Typography>
                    </CardContent>
                </Card>
            </Box>
        </Stack>
    )
}

export default TeamMemberPage