import { useState } from 'react'
import Microlink from '@microlink/react'
import { services, recommendedResources } from '../data'
import { Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material'

const Programs = () => {
    const [selectedIndex, setSelectedIndex] = useState(null)

	const handleListItemClick = (event, index) => {
		setSelectedIndex(index)
	}

    return (
		<Container sx={{ maxWidth: '100%', bgcolor: '#e3e3e3'}}>
			<Grid container spacing={4} sx={{maxWidth: '100%', mt: 2, mb: 2}}>
				{services.map((service, index) => (
					<Grid key={index} item xs={12} sm={4} display="flex" justifyContent="center" alignItems="center">
						<Card sx={{maxWidth: 500, display: 'inline-block', p: 2}} >
							<CardMedia sx={{height: 60}}>{service.icon}</CardMedia>
							<CardActions>
								<Button sx={{textTransform: 'none'}}>
									<Typography variant='h6' sx={{fontWeight: 'bold'}}>
										{service.title}
									</Typography>
								</Button>
							</CardActions>
							<CardContent>
								<Typography variant='body'>{service.desc}</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
			<div style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
				<Typography variant='h6'>Check out the following links</Typography>
				{recommendedResources.map((resource, index) => (
					<div key={index}>
						<Typography variant='body1'>
							{resource.title}
						</Typography>
						<Microlink url={resource.url} media="logo" />
					</div>
				))}
			</div>
		</Container>
    )
}

export default Programs