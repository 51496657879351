import React from 'react'
import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'


const TeamMember = ({ member }) => {
	const navigate = useNavigate()

    const handleExpandClick = (id) => {
		navigate(`/team/${id}`)
    };

	return (
		<>
			<Card sx={{maxWidth: 300, display: 'inline-block'}} >
				<CardMedia sx={{height: 150}}>
					<Avatar alt={member.name} src={member.img} sx={{justifyContent: 'center', alignItems: 'center', height: 150, width: 150, ml: 9, mt: 2}}/>
				</CardMedia> 
				<CardContent>
					<Typography variant='h4'>{member.name}</Typography>
					<Typography variant='h6'>{member.title}</Typography>
				</CardContent>
				<CardActions>
					<Button size="small" onClick={() => handleExpandClick(member.id)}>Show Details</Button>
				</CardActions>
			</Card>
		</>
	)
}

export default TeamMember