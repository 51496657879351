import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { newsletterForm } from '../data'

const Hero = () => {
    return (
        <div>
            <Box component='span' sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <Link href={newsletterForm.url} target="_blank" sx={{bgcolor: '#0475f6',  underline: 'none', padding: '15px', width: '100%'}} >
                    <Typography variant='h5' textAlign='center' sx={{color: 'white'}}>{newsletterForm.name}</Typography>
                </Link>
            </Box>
        </div>
    )
}

export default Hero