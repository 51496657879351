import { Box, Container, Typography } from '@mui/material'
import { joinus } from '../data'

const Partners = () => {
    return (
        <Container sx={{ maxWidth: 'false', height: '71vh', display: 'flex', flex: 1, flexDirection: 'column'}}>
            <Box flex={1} overflow="auto" justifyContent='center'>
                <div style={{paddingTop: '2rem'}}>
                    <Typography variant='body' sx={{pt: 2, display: 'inline-block'}}>{joinus.desc}</Typography>
                </div>
                <div style={{ paddingTop: '2rem', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='h5' sx={{pt: 2, display: 'inline-block'}}>{joinus.contact.message}</Typography>
                    <Typography variant='h6' sx={{pt: 2, display: 'inline-block'}}>Email: {joinus.contact.email}</Typography>
                </div>
            </Box>
        </Container>
    )
}

export default Partners