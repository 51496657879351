import React from 'react'
import { Stack } from '@mui/material'
import About from '../Components/About'
import Header from '../Components/Header'

const AboutPage = () => {
	return (
		<Stack spacing={0} backgroundColor='#e3e3e3'>
			<Header text="Meet Our Team" />
			<About />
		</Stack>
	)
}

export default AboutPage