import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppBar, Avatar, Box, Button, Toolbar, Tooltip, Typography, Menu, MenuList, MenuItem, IconButton} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import theme from '../utils/theme';

const pages = ['About', 'Programs', 'Spotlights', 'Events', 'Join Us'];

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const navigate = useNavigate()
    const open = Boolean(anchorEl)

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = () => {
		navigate(`/`)
    }
    
    const goToPage = (page) => {
        navigate(`/${page.toLowerCase()}`)
    }

    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <AppBar position='sticky' sx={{bgcolor: '#6f236f'}}>
                <Toolbar>
                    <Button onClick={handleClick}>
                        <Avatar alt="logo" src="/The U-Turn-logos_transparent.png" sx={{ height: '100px', width: '100px' }} />
                    </Button>
                    <Typography 
                        variant="h6"
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                        }}
                    >
                        The U-Turn Women Enterprise
                    </Typography>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, index) => (
                            <Button
                                key={index}
                                onClick={() => goToPage(page)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip>
                            <IconButton 
                                fontSize='large' 
                                color='inherit' 
                                onClick={handleOpen}
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu 
                        id="account-menu"
                        anchorEl={anchorEl}
                        open={open} 
                        onClose={handleClose} 
                        onClick={handleClose}
                        elevation={0}
                        sx={{ 
                            display: { xs: 'flex', md: 'none' }, 
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiMenuIcon-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 35,
                                width: 15,
                                height: 15,
                                bgcolor: 'inherit',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList>
                            {pages.map((page, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => goToPage(page)}
                                >
                                    {page}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}

export default Navbar;