import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import TeamMember from './pages/TeamMember'
import ProgramsPage from './pages/ProgramsPage'
import AboutPage from './pages/About'
import SpotlightPage from './pages/Spotlight'
import Footer from './Components/Footer'
import { CssBaseline } from '@mui/material'
import Navbar from './Components/Navbar'
import EventsPage from './pages/EventsPage'
import PartnersPage from './pages/Partners'
import './App.css'
import Hero from './Components/Hero'

export const TokenContext = React.createContext(null)

const App = () => {
    return (
        <div className='App'>
            <CssBaseline />
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path='/events' element={<EventsPage />} />
                    <Route path="/team/:id" element={<TeamMember />} />
                    <Route path="/programs" element={<ProgramsPage />} />
                    <Route path="/spotlights" element={<SpotlightPage />} />
                    <Route path="/join us" element={<PartnersPage />} />
                </Routes>
                <Hero />
                <Footer />
            </BrowserRouter>
        </div>
    )
}

export default App