import { 
    Facebook, Instagram, Twitter, YouTube, WorkHistoryOutlined, SportsOutlined, Article
} from "@mui/icons-material";

const TikTok = ({ color = "#6f236f" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="30px"
      height="30px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

export const team = [
    {
        id: 0,
        name: 'Akuol Philip',
        title: 'Director of Operations and Management',
        img: '/rhoda_2.jpg',
        desc: 'Akuol offers private mentorship and coaching to professionals and entrepreneurs in these domains:  business planning/management, market research, business analysis, communication management, relationship engagement, career coaching, academic/creative writing, resume/cover letter writing, guest lecturing, workshop facilitation, speech writing, public speaking, keynote/motivational storytelling.'
    },
    {
        id: 1,
        name: 'Achieu Alier',
        title: 'Director of Research and Outreach',
        img: '/achieu.jpg',
        desc: 'Achieu researches and curates topics to streamline guest outreach and correspondence coordination. Areas of expertise: Business Development, Business Management, Research, Data Analysis'
    },
    {
        id: 2,
        name: 'Alakiir Deng',
        title: 'Director of Sales and Communications',
        img: '/alakiir.jpg',
        desc: 'Alakiir offers private coaching to those wanting to grow their knowledge and skills in sales and marketing, business writing, digital communication, research, data analysis, content creation, social media management, search engine optimization, project management.'
    },
    {
        id: 3,
        name: 'Aluel Mawien',
        title: 'Director of Engagement and Africa Lead',
        img: '/aluel.jpg',
        desc: 'A dedicated leader to handle communication with the audience online/offline. \n\n Areas of Expertise: Customer Engagement, Research, Business Management, Social Media Management, Creative Writing'
    },
    {
        id: 4,
        name: 'Liz Adit',
        title: 'Strategic Planning and Development',
        img: '/liz.jpg',
        desc: 'Liz provides consultancy services related to strategic planning and development of our enterprise.'
    },
    {
        id: 5,
        name: 'Atong Mabil',
        title: 'Strategic Communication and Media',
        img: '/atong.jpeg',
        desc: 'Atong develops marketing and communication and media strategies for The U-Turn aimed at increasing brand awareness and media relations.'
    }
]

export const footerInfo = {
    skills: ['Engineering', 'IT', 'Finance', 'Law'],
    about: {
        team: 'Meet Our Team',
        mission: 'To empower our communities through the women and youth: by equipping them to make a difference',
        vision: 'To create culture, community, and connections for lasting positive impact',
        values: [
            {
                title: 'Women & Youth Centered',
                desc: 'Our work is informed by the understanding that women and youth in the communities we serve have cultural, political and social barriers to equal socio-economic participation in the society.'
            },
            {
                title: 'Empowerment Focused',
                desc: 'Our work is guided by the recognition of the existing potential women and youth possess. We believe that a little guidance will help them realize such potential and use it for positive socio-economic outcome for families and communities.'
            },
            {
                title: 'Socio-Economic Empowerment',
                desc: 'Our work exists to fill the socio-economic gaps women and youth have in terms of access to resources, mentorship, and coaching necessary to achieve their dreams and goals.'
            },
            {
                title: 'Technology & Innovation',
                desc: 'We aim to leverage the use of emerging technologies, education and lived expertise to address problems women and youth experience when pursuing their goals and dreams in their new homes.'
            }
        ]
    },
    contact: {
        sales: 'Sales',
        support: 'Support',
        socialMedia: [
            {
                platform: <Facebook sx={{fontSize: 30}}/>,
                url: 'https://www.facebook.com/Uturnow/about'
            },
            {
                platform: <Instagram sx={{fontSize: 30}}/>,
                url: 'https://instagram.com/uturnow180?igshid=MzRlODBiNWFlZA=='
            },
            {
                platform: <YouTube sx={{fontSize: 30}}/>,
                url: 'https://www.youtube.com/@uturnnow/videos?fbclid=IwAR2zH5yDXjFC6Zf33iAT1FqYZjkyq08ieptE3BVxlZLo-aiIZ9XkQzyrHJQ'
            },
            {
                platform: <Twitter sx={{fontSize: 30}}/>,
                url: 'https://www.twitter.com'
            },
            {
                platform: <TikTok sx={{fontSize: "large"}}/>,
                url: 'https://www.tiktok.com'
            }
        ]
    },
    links: [
        {
            service: 'Job Postings',
            url: 'https://www.facebook.com/Uturnow/about'
        },
        {
            service: 'Professional Development Courses',
            url: 'https://www.facebook.com/Uturnow/about'
        },
        {
            service: 'Partner Profiles',
            url: 'https://www.facebook.com/Uturnow/about'
        }
    ]
}

export const newsletterForm = {
    "name": "Sign up for our Newsletter",
    "url": "https://forms.gle/6yZ5jXH1oYG42VVp9"
}

export const services = [
    {
        title: 'Talk Show',
        desc: "We host a U-Turn talk show to inspire change and impact lives through the sharing of ideas, experiences, and resources. Our highly experienced and enthusiastic women co-hosts discuss a wide range of topics, and engage in interviews, debates, social commentary, question and answer sessions, and storytelling. If you are interested in participating in our talk show",
        icon: <WorkHistoryOutlined color="primary" sx={{ fontSize: 60 }} />
    },
    {
        title: 'Career Coaching',
        desc: "Our team of qualified professional offers 1:1 career coaching at a bargain price. Please get in touch with us if you want to get ahead by leveraging our expertise in resume and cover letter writing, public speaking, workshop facilitation, business planning, marketing, digital communication, business analysis, technology trends, demand forecasting, market dynamics, and many more.",
        icon: <SportsOutlined color="primary" sx={{ fontSize: 60 }} />
    },
    {
        title: 'Recommended Resources',
        desc: "We believe in the power of information. If you are interested in personal and professional development, please watch this space for information on jobs, scholarships, and other opportunities.",
        icon: <Article color="primary" sx={{ fontSize: 60 }} />
    }
]

export const recommendedResources = [
    {
        title: 'Scholarships',
        url: 'https://scholarshipscafe.com/'
    }
]

export const spotlights = {
    desc: "We amplify the incredible work of changemakers in our community to inspire, create awareness, and connections for lasting impact. Enjoy the following spotlights and don't forget to share the joy with your networks",
    items: [
        {
            name: 'Rebecca Deng',
            title: 'Author & Advocate',
            paragraphs: [
                "Rebecca Deng is an Author, International Speaker, Girl's Education Champion, Advocate for Women and Children who have been traumatized and victimized by war, and many more.",
                'She authored an empowering memoir “What They Meant for Evil: How a Lost Girl of Sudan Found Healing, Peace, and Purpose in the Midst of Suffering”, and co-authored “Voices of Lament: Reflections on Brokenness and Hope in a World Longing for Justice”.',
                "Rebecca Deng's transformative work in United Stated, and the Great Lake Region of Africa, particularly Kenya, Rwanda, Burundi, Congo, and South Sudan has been recognized by many. Her work appeared in big media including Forbes!",
                "She holds a Bachelor of International Development and Social Work, and a Master of Organizational Leadership."
            ],
            img: '/rebeccaDeng.jpeg',
            url: 'https://www.youtube.com/embed/RK3P-ZN16CU'
        },
        {
            name: 'Abul Mayen',
            title: "Co-founder of Twic East's Scholarship Program",
            paragraphs: [
                "Abul is a leader in health. She holds a Bachelor of Medical Science, a master of pharmacy, and master of public health.",
                "She is a co-founder of the Twic East Girls' Scholarship Program, a program that is empowering girls through education sponsorship, mentorship, and resources.",
                "Abul's transformative work in Australia, Kenya, South Sudan and the rest of the world has been recognized by many, and she was recently featured among the 40-under-40 women leaders of South Sudan."
            ],
            img: '/abulMayen.jpeg',
            url: 'https://www.youtube.com/embed/sSWtcWsIEcE'
        },
        {
            name: 'Majier Madol',
            title: 'Founder of Maisha Canada',
            paragraphs: [
                "Majier is the founder of Maisha Canada, a platform aiming at providing mentorship and resources to young people to help them translate their scholastic achievements into financial honors and distinctions, in other words, real world success.",
                "Our discussion focused on the importance of mentorship and role modeling in career and professional growth in the context of adapting to challenges of immigrating to Canada."
            ],
            img: '/majierMadol.jpeg',
            url: 'https://www.youtube.com/embed/Fm3i1YJeBnc'
        },
        {
            name: 'Judith Richard',
            title: 'Radio Presenter on City FM & Host of the Daniel Mwaka Show',
            paragraphs: [
                "Judith is our rising star, a peace builder, youth activist, and media personality.",
                "Judith was recently recognized as one of the 40-under-40 South Sudan Women Leaders and was also among the 100 Leading and Emerging Women Leaders in Africa alongside notable public figures like Rt.Hon. Jemma Nunu Kumba!"
            ],
            img: '/judithRichard.jpeg',
            url: 'https://www.youtube.com/embed/INsa3UN3sUg'
        },
        {
            name: 'Monica Malith',
            title: 'President of the UoN Student Association',
            paragraphs: [
                "Monica's story is nothing short of spectacular.",
                "Monica was recently elected the President of the University of Nairobi Students Association. She is the first ever international student to ascend to that position at UoN. And we think she is the first ever south Sudanese woman to lead a student organization at that scale.",
                "As the students' leader, she joins a distinguished company of leaders who held this position including...Senior Counsel James Orengo and lawmaker, Babu Owino."
            ],
            img: '/monicaMalith.jpeg',
            url: 'https://www.youtube.com/embed/bYzScys52wQ'
        }
    ]
}

export const events = {
    title: 'Upcoming Events',
    desc: 'The U-Turn events are currently free virtual workshops open to all, including those who are not U-Turn members. These 1-hour events are designed to educate and empower women and youth in all aspects of their personal and professional development. If you are interested to be part of the workshop, please sign up ',
    eventsList: [
        {
            title: 'Public Speaking',
            date: 'June 2024',
            url: ''
        },
        {
            title: 'Resume and Cover Letter Writing',
            date: 'August 2024',
            url: ''
        },
        {
            title: 'Social Media Marketing',
            date: 'October 2024',
            url: ''
        },
        {
            title: 'Business Planning',
            date: 'December 2024',
            url: ''
        }
    ]
}

export const joinus = {
    desc: 'The U-Turn strives to collaborate with changemakers, community organizations, refugees and immigrants serving organizations, government agencies, and other stakeholders who are committed to empowerment of women and youth locally and globally.',
    contact: {
        message: "Let’s make the difference together!",
        email: 'info@uturnow.com'
    }
}

export const aboutUs = {
    desc: "The U-Turn is a Social Enterprise dedicated to empowerment of women and youths through education, coaching, mentorship, entrepreneurship, research and advocacy."
}