import React from 'react'
import { Container, Typography } from '@mui/material'

const Header = ({ text, flag }) => {
    return (
        <Container maxWidth="l" sx={{p: 4, backgroundColor: "#030321"}}>
            {flag ?
                <Typography variant='h5' sx={{color: "white", textAlign: "center"}} >
                    {text}
                </Typography>
                :
                <Typography variant='h2' sx={{color: "white", textAlign: "center"}} >
                    {text}
                </Typography>
            }
        </Container>
    )
}

export default Header