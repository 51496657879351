import React from 'react'
import { Container, Stack, Typography } from '@mui/material'
import Spotlight from '../Components/Spotlight'
import Header from '../Components/Header'
import { spotlights } from '../data'

const SpotlightPage = () => {
    return (
        <Stack spacing={0} backgroundColor='#e3e3e3'>
            <Header text="Our Spotlights" />
            <Container maxWidth="l" sx={{p: {xs: 2, md: 10}, mb: 1, bgColor:'#e3e3e3'}}>
                <Typography variant='h5' sx={{textAlign: {md: "center", xs: "left"}, fontSize: {xs: 'small', md: 'large'}}} >{spotlights.desc}</Typography>
            </Container>
            <Spotlight items={spotlights.items}/>
        </Stack>
    )
}

export default SpotlightPage