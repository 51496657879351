import { Container, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import React from 'react'
import { footerInfo } from '../data'

const Home = () => {
    const { about } = footerInfo
    
    return (
        <Container maxWidth="md" sx={{marginTop: 5, marginBottom: 5, display: 'flex', flexDirection: 'column'}}>
            <Paper elevation={3} sx={{ marginBottom: 5,display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant='h4' padding={2}>Our Vision</Typography>
                <Typography fontSize={18} padding={2}>{about.vision}</Typography>
            </Paper>
            <Paper elevation={3} sx={{marginBottom: 5,display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant='h4' padding={2}>Our Mission</Typography>
                <Typography fontSize={18} padding={2}>{about.mission}</Typography>
            </Paper>
            <Paper elevation={3} sx={{marginBottom: 5,display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant='h4' padding={2}>Our Core Values</Typography>
                <List>
                    {about.values.map((value, index) => {
                        return (
                            <ListItem key={index} alignItems='center'>
                                <ListItemText
                                    primary={value.title}
                                    secondary={
                                        <Typography variant='body1' gutterBottom>{value.desc}</Typography>
                                    }
                                 />
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
        </Container>
    )
}


export default Home