import React from 'react'
import { Box, Button, Container, Link, Grid, Typography } from '@mui/material'
import { footerInfo } from '../data';
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import theme from '../utils/theme';

const Footer = () => {
    const {skills, contact, links} = footerInfo
    const {sales, support, socialMedia} = contact
    
    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <>
                <Box
                    component="footer"
                    sx={{
                        backgroundColor: "#030321",
                        position:'bottom',
                        left: 0,
                        bottom: 0,
                        right:0,
                        padding: 2
                    }}
                    xs={{
                        fontSise: '2rem'
                    }}
                >
                    <Container maxWidth="lg">
                        <Grid 
                            item 
                            sx={{
                                display: { xs:'none', md: 'flex' }, 
                                justifyContent: 'space-between'
                            }}
                        >
                            <Grid item xs={12} sm={4}>
                                <Typography variant='h6' color="#6f236f" gutterBottom>
                                    Skills
                                </Typography>
                                {skills.map((skill, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            sx={{ color: '#6f236f', display: 'block' }}
                                        >
                                            {skill}
                                        </Button>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" color="#6f236f" gutterBottom>
                                    About Us
                                </Typography>
                                <Button sx={{ color: '#6f236f', display: 'block' }}>
                                    Meet Our Team
                                </Button>
                                <Button sx={{ color: '#6f236f', display: 'block' }}>
                                    Vision
                                </Button>
                                <Button sx={{ color: '#6f236f', display: 'block' }}>
                                    Mission
                                </Button>
                                <Button sx={{ color: '#6f236f', display: 'block' }}>
                                    Values
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" color="#6f236f" gutterBottom>
                                Contact Us
                                </Typography>
                                <Button sx={{ color: '#6f236f', display: 'block' }}>
                                    {sales}
                                </Button>
                                <Button sx={{ color: '#6f236f', display: 'block' }}>
                                    {support}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='h6' color="#6f236f" gutterBottom>
                                    Quick Links
                                </Typography>
                                {links.map((link, index) => {
                                    return (
                                        <Link key={index} href={link.url} color="#6f236f" underline='none'>
                                            <Typography variant='h6' color="#6f236f" gutterBottom>
                                                {link.service}
                                            </Typography>
                                        </Link>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" color="#6f236f" gutterBottom>Follow Us</Typography>
                                {socialMedia.map((app, index) => {
                                    return (
                                        <Link key={index} href={app.url} color="#6f236f">
                                            {app.platform}
                                        </Link>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Typography 
                            variant="h6" 
                            color="#6f236f" 
                            gutterBottom
                            sx={{ display: { md: 'none' }, textAlign: 'center' }} 
                        >
                            Follow Us
                        </Typography>
                        <Box 
                            sx={{
                                display: { xs:'flex', md: 'none' }, 
                                justifyContent: 'space-between'
                            }}
                        >
                            {socialMedia.map((app, index) => {
                                return (
                                    <Link key={index} href={app.url} color="#6f236f">
                                        {app.platform}
                                    </Link>
                                )
                            })}
                        </Box>
                        <Box mt={5}>
                            <Typography variant="body2" color="#6f236f" align="center">
                                {"Copyright © "}
                                <Link color="#6f236f" href="https://uturnow.com/"> The U-Turn </Link>
                                {" "}{new Date().getFullYear()}{"."}
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            </>
        </ ThemeProvider>
    )
}

export default Footer