import { Container, Card, CardMedia, Avatar, CardContent, Typography } from '@mui/material'
import YoutubeEmbeded from './YoutubeEmbeded'

const Spotlight = ({items}) => {
    return (
		<Container sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 4, marginBottom: 2, pl: 2, pr: 2 }}>
            {items && items.map((item, index) => (
                <Card key={index} sx={{ maxWidth: 800, display: 'inline-block', padding: 2}}>
                    <CardMedia sx={{height: 150}}>
                        <Avatar alt={item.name} src={item.img} sx={{justifyContent: 'center', alignItems: 'center', height: 150, width: 150, ml: 9, mt: 2}}/>
                    </CardMedia> 
                    <CardContent>
                        <Typography variant='h4'>{item.name}</Typography>
                        {item && item.paragraphs.map((paragraph, index) => (
                            <div key={index}>
                                <Typography variant='p'>{paragraph}</Typography>
                                <br />
                                <br />
                            </div>
                        ))}
                        <Typography variant='h6'>
                            Check out our interview
                            <span style={{padding: 5}}>{item.name}</span>
                            below...
                        </Typography>
                    </CardContent>
                    <Container>
                        <div style={{ paddingTop: "20px" }}>
                            <YoutubeEmbeded url={item.url} />
                        </div>
                    </Container>
                </Card>
            ))}
        </Container>
	)
}

export default Spotlight