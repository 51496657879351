import { Box, Container, Link, Typography } from '@mui/material'
import { events, newsletterForm } from '../data'

const Account = () => {
    return (
        <Container sx={{ maxWidth: 'false', height: '71vh', display: 'flex', flex: 1, flexDirection: 'column'}}>
            <Box flex={1} overflow="auto" sx={{display: 'flex', flexDirection: 'column'}}>
                <div style={{ paddingTop: '2rem' }}>
                    <Typography variant='p'>{events.desc}</Typography>
                    <Link href={newsletterForm.url} target="_blank" sx={{ textDecoration: 'none' }}>
                        <Typography variant='p'>here</Typography>
                    </Link>
                </div>

                <div style={{ paddingTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Typography variant='h3'>{events.title}</Typography>
                </div>

                <div style={{ paddingTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {events.eventsList.map(event => (
                        <Box key={event.id}>
                            <Typography variant='h6'>{event.title}</Typography>
                            <Typography variant='p'>{event.date}</Typography>
                        </Box>
                    ))}
                </div>
            </Box>
        </Container>
    )
}

export default Account