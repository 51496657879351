import React from 'react'
import { Container, Link, Stack, Typography } from '@mui/material'
import Programs from '../Components/Programs'
import Header from '../Components/Header'
import { newsletterForm } from '../data'

const ProgramsPage = () => {
    return (
        <Stack spacing={0} sx={{ bgcolor: '#e3e3e3' }}>
            <Header text="Our Programs" />
            <Container maxWidth="l" sx={{ p: {xs: 4, md: 10}, mb: 1, bgcolor: '#e3e3e3'}}>
				<Typography variant='h6' sx={{ textAlign: {md: "center", xs: "left"}, fontSize: {xs: 'small', md: 'large'} }} >
					Our team of qualified professional offers the following programs. Please  
                    <Link href={newsletterForm.url} sx={{pl: 1, pr: 1}}>
                        contact us 
                    </Link>
                    for more information if you are interested in any of these offerings.
				</Typography>
			</Container>
			<Programs />
        </Stack>
    )
}

export default ProgramsPage